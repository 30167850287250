<template>
  <div class="introduction">
    <mds-layout-grid>
      <mds-row>
        <mds-col>
          <div class="product-key-features-title">{{ title }}</div>
          <div class="center-align">
            <span class="center-align-normal-text"> CMBS Intel for Surveillance gives you a </span>
            <span class="center-align-bold-text">centralized view of the most important</span>
          </div>
          <div class="center-align">
            <span class="center-align-bold-text"> loan and bond level changes</span>
            <span class="center-align-normal-text"> and trends across your CMBS deals</span>
          </div>
          <mds-button
            class="product-key-benefits-action-btn"
            variation="primary"
            size="large"
            @click="toggle = !toggle"
          >
            Request a trial
          </mds-button>
          <mds-modal
            v-model="toggle"
            class="mcia-get-started-form-model"
            aria-label="Default Modal"
            size="medium"
            :width="'900px'"
          >
            <get-started-form
              form-id="get-started-form-model"
              @exit="getStartedFormToggled"
              @toggleNotification="getStartedToggleNotification"
            />
          </mds-modal>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import MdsModal from '@mds/modal';
import GetStartedForm from '@/components/GetStartedForm/GetStartedForm.vue';

export default {
  name: "Solution",
  components : {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsModal,
    GetStartedForm,
  },
  data() {
    return {
      title: 'Our Solution',
      toggle: false,
      showNotification: false,
    };
  },
  methods:{
    getStartedFormToggled() {
      this.toggle = !this.toggle;
    },
    getStartedToggleNotification(toggle) {
      this.showNotification = toggle;
      if (this.showNotification) {
        setTimeout(() => {
          this.showNotification = false;
        }, 1000);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@mds/constants';
  @import '@mds/fonts';
  @import '@mds/typography';

.product-key-features {
  margin: 0 auto;
  &-title {
    text-align: center;
    @include mds-level-2-heading($bold: true);
    color: $mds-text-color-primary;
    padding-top: calc(#{$mds-space-6-x} + #{$mds-space-quarter-x});
    padding-bottom: 35px;
  }
}
.introduction {
  background-color: #f9f9f9;
  height: 100%;
  width: 100%;
  top: 442px;
  display: flex;
  justify-content: center;
  @include mds-level-3-heading()
}
.center-align {
  text-align: center;
  &-bold-text {
    font-weight: 700;
    line-height: 34px;
    font-size: 28px;
  }
  &-normal-text {
    font-weight: 300;
    font-size: 28px;
    line-height: 43px;
  }
}
.product-key-benefits-action-btn {
  width: 153px;
  height: 42px;
  left: 328px;
  margin-top: 35px;
  margin-bottom: calc(#{$mds-space-6-x} + #{$mds-space-quarter-x});
  align-items: center;
  @media (max-width: $mds-breakpoint-m) {
    left: 115px;
  }
}
</style>